import React, { useEffect, useState } from "react"
import ProviderDataService from "../../services/ProviderDataService"
import Header from "../../components/External/Header"
import Footer from "../../components/External/Footer"

const ProviderManagement = () => {
  const [providers, setProviders] = useState([])
  const [editKey, setEditKey] = useState(null)
  const [editData, setEditData] = useState({ name: "", email: "", position: "", message: "" })

  // Load providers on component mount
  useEffect(() => {
    fetchProviders()
  }, [])

  const fetchProviders = async () => {
    try {
      const data = await ProviderDataService.getAll()
      setProviders(data)
    } catch (error) {
      console.error("Error fetching provider data:", error)
    }
  }

  const handleDelete = async key => {
    if (window.confirm("Are you sure you want to delete this provider?")) {
      try {
        await ProviderDataService.deleteProvider(key)
        fetchProviders()
      } catch (error) {
        console.error("Error deleting provider:", error)
      }
    }
  }

  const handleEdit = provider => {
    setEditKey(provider.key)
    setEditData({
      name: provider.name,
      email: provider.email,
      position: provider.position,
      message: provider.message,
    })
  }

  const handleCancel = () => {
    setEditKey(null)
    setEditData({ name: "", email: "", position: "", message: "" })
  }

  const handleChange = e => {
    setEditData({ ...editData, [e.target.name]: e.target.value })
  }

  const handleUpdate = async () => {
    try {
      await ProviderDataService.updateProvider(editKey, editData)
      fetchProviders()
      handleCancel()
    } catch (error) {
      console.error("Error updating provider:", error)
    }
  }

  return (
    <>
      <Header />
      <div className="page-content">
        <h2>Provider Management</h2>

        {providers.length === 0 ? (
          <p>No provider records found.</p>
        ) : (
          <table border="1" cellPadding="8" style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead style={{ background: "#f1f1f1" }}>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Position</th>
                <th>Message</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {providers.map(provider => (
                <tr key={provider.key}>
                  {editKey === provider.key ? (
                    <>
                      <td>
                        <input
                          type="text"
                          name="name"
                          value={editData.name}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          type="email"
                          name="email"
                          value={editData.email}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="position"
                          value={editData.position}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="message"
                          value={editData.message}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <button onClick={handleUpdate}>Save</button>
                        <button onClick={handleCancel} style={{ marginLeft: "8px" }}>Cancel</button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>{provider.name}</td>
                      <td>{provider.email}</td>
                      <td>{provider.position}</td>
                      <td>{provider.message}</td>
                      <td>
                        <button onClick={() => handleEdit(provider)}>Edit</button>
                        <button
                          onClick={() => handleDelete(provider.key)}
                          style={{ marginLeft: "8px", color: "red" }}
                        >
                          Delete
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <Footer />
    </>
  )
}

export default ProviderManagement
