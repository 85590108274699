import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const Authmiddleware = ({ children }) => {
  const location = useLocation();

  // Assume all public routes are accessible without authentication
  const publicRoutes = [
    "/login",
    "/register",
    "/home",
    "/aboutus",
    "/contactus"
    // Add other public routes here
  ];

  const isAuthenticated = localStorage.getItem("authUser");

  // Redirect to /home if the user visits the root path
  if (location.pathname === "/") {
    return <Navigate to="/home" replace />;
  }

  // Allow public routes without requiring authentication
  if (publicRoutes.includes(location.pathname)) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  // Redirect unauthenticated users trying to access protected routes
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default Authmiddleware;
