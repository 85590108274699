import React, { useState } from 'react';
import { sendEmail } from '../../services/emailService';
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"
import ClientDataService from '../../services/ClientDataService'

const ClientForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        city: '',
        businessName: '',
        businessAddress: '',
        noOfHomes: '',
        serviceLocations: '',
        serviceAddresses: '',
        availableHours: '',
        staffRequired: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const templateParams = {
            to_name: `${formData.firstName} ${formData.lastName}`,
            from_name: 'Carers-Tip Recruitment Team',
            message: `
                Dear ${formData.firstName} ${formData.lastName},

                Thank you for submitting your provider details. Below is the information you provided:

                📞 Phone No: ${formData.phone}
                📧 Email: ${formData.email}
                🏙️ City/Town: ${formData.city}
                🏢 Registered Business Name: ${formData.businessName}
                📍 Registered Business Address: ${formData.businessAddress}
                🏠 No. of Homes: ${formData.noOfHomes}
                📍 Service Locations: ${formData.serviceLocations}
                🏠 Service Addresses: ${formData.serviceAddresses}
                ⏰ Available Monthly Hours: ${formData.availableHours}
                👥 Staff Required: ${formData.staffRequired}

                Our team will contact you shortly.
            `,
            to_email: formData.email
        };

        await ClientDataService.createClient({
            ...formData,
            createdAt: Date.now()
          })

        console.log('📩 Template Params:', templateParams);  // Debugging log

        try {
            await sendEmail(templateParams, true); // Force Axios for better visibility


            alert('✅ Application submitted successfully! Check your email for confirmation.');
        } catch (error) {
            alert('❌ Failed to send confirmation email. Please try again.');
        }
    };

    return (
          <>
             <Header />
             <div className="page-contentc">
          
            <form onSubmit={handleSubmit} style={{ maxWidth: "900px", margin: "0 auto", display: 'block' }}>
            <h2 style={{marginTop: '20px'}}>Register</h2> 
            <div>
                    <label>First Name:</label>
                    <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div>
                    <label>Last Name:</label>
                    <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div>
                    <label>Phone No:</label>
                    <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div>
                    <label>City/Town:</label>
                    <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div>
                    <label>Registered Business Name:</label>
                    <input
                        type="text"
                        name="businessName"
                        value={formData.businessName}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div>
                    <label>Registered Business Address:</label>
                    <input
                        type="text"
                        name="businessAddress"
                        value={formData.businessAddress}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div>
                    <label>No. of Homes:</label>
                    <input
                        type="number"
                        name="noOfHomes"
                        value={formData.noOfHomes}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div>
                    <label>Service Locations:</label>
                    <input
                        type="text"
                        name="serviceLocations"
                        value={formData.serviceLocations}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div>
                    <label>Service Addresses:</label>
                    <input
                        type="text"
                        name="serviceAddresses"
                        value={formData.serviceAddresses}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div>
                    <label>No. of Available Monthly Hours:</label>
                    <input
                        type="number"
                        name="availableHours"
                        value={formData.availableHours}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div>
                    <label>No. of Staff Required:</label>
                    <input
                        type="number"
                        name="staffRequired"
                        value={formData.staffRequired}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div style={{ paddingTop: "10px", marginBottom:'20px' }}>
                <button type="submit">Submit Application</button>
                </div>
            </form>
        </div>
        <Footer />
        </>
    );
};

export default ClientForm;
