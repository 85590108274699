import React, { useState, useEffect } from "react";
import TimesheetDataService from "services/TimesheetDataService";
import WorkersDataService from "services/WorkersDataService";
import { Popup } from "devextreme-react/popup";
import { Button } from "devextreme-react/button";
import { Form, SimpleItem, RequiredRule } from "devextreme-react/form";
import DataGrid, { Column } from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import './approve.css';
import axios from "axios"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { sendEmail } from '../../services/emailService';

const API_URL =
  process.env.NODE_ENV === "production"
    ? "/api/send-email" // Vercel API route
    : "http://localhost:3000/api/send-email" 


const ApproveTimesheets = () => {
  const [timesheets, setTimesheets] = useState([])
  const [selectedTimesheet, setSelectedTimesheet] = useState({}) // Initialize as empty object
  const [showPopup, setShowPopup] = useState(false)
  const [disputeReason, setDisputeReason] = useState("")
  const [status, setStatus] = useState("") // Track the selected status
  const [isReasonVisible, setIsReasonVisible] = useState(false)
  const [workerFullName, setWorkerFullName] = useState("") // State to store worker full name
  const [newAttachments, setNewAttachments] = useState([]) // Store new attachments before upload

  const [searchTerm, setSearchTerm] = useState("") // Global search term
  const [filteredTimesheets, setFilteredTimesheets] = useState([]) // Filtered timesheets

  // Fetch timesheets on component mount
  useEffect(() => {
    const fetchTimesheets = async () => {
      try {
        const allTimesheets = await TimesheetDataService.getAllTimesheets()
        setTimesheets(allTimesheets)
        setFilteredTimesheets(allTimesheets) // Initialize filtered timesheets
      } catch (error) {
        console.error("Error fetching timesheets:", error)
      }
    }

    fetchTimesheets()
  }, [])

  const handleSearchChange = e => {
    const term = e.target.value.toLowerCase()
    setSearchTerm(term)

    if (term === "") {
      setFilteredTimesheets(timesheets) // Reset if search is empty
    } else {
      // Filter timesheets based on the global search term
      const filtered = timesheets.filter(
        ts =>
          ts.jobID.toLowerCase().includes(term) ||
          ts.workerID.toLowerCase().includes(term) ||
          ts.status.toLowerCase().includes(term) ||
          (ts.fromDuration && ts.fromDuration.toLowerCase().includes(term)) ||
          (ts.toDuration && ts.toDuration.toLowerCase().includes(term))
      )
      setFilteredTimesheets(filtered)
    }
  }

  const handleShowPopup = async timesheet => {
    const timesheetWithAttachments = {
      ...timesheet,
      attachments: timesheet.attachments || [], // Ensure attachments is always an array
    }

    setSelectedTimesheet(timesheetWithAttachments) // Safe state update
    setShowPopup(true)
    setStatus(timesheetWithAttachments.status || "") // Initialize the status
    setDisputeReason(timesheetWithAttachments.disputeReason || "") // Set dispute reason if it exists
    setIsReasonVisible(
      timesheetWithAttachments.status === "Dispute" ||
        timesheetWithAttachments.status === "Rejected"
    ) // Show Reason if status is already Dispute/Rejected

    // Fetch and set the worker's full name
    if (timesheetWithAttachments.workerID) {
      const workerName = await fetchWorkerName(
        timesheetWithAttachments.workerID
      )
      
      // alert(JSON.stringify( timesheetWithAttachments));

      setWorkerFullName(workerName) // Store the full name in state
    }
  }

  const handleClosePopup = () => {
    setShowPopup(false)
    setSelectedTimesheet({}) // Reset the selected timesheet to an empty object
    setDisputeReason("") // Reset dispute reason
    setStatus("") // Reset status
    setIsReasonVisible(false) // Reset Reason visibility
    setWorkerFullName("") // Reset worker full name
    setNewAttachments([]) // Reset new attachments
  }

  const fetchWorkerName = async workerID => {
    try {
      const workerFullName = await WorkersDataService.getWorkerFullNameById(
        workerID
      )
      return workerFullName // Return the full name
    } catch (error) {
      console.error("Error fetching worker full name:", error)
      return "" // Return empty string if there's an error
    }
  }

  // Handle delete timesheet
  const handleDeleteTimesheet = async timesheetID => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this timesheet?"
    )

    if (confirmDelete) {
      try {
        // Remove timesheet from the database
        await TimesheetDataService.deleteTimesheet(timesheetID)

        // Update the timesheets state to remove the deleted timesheet
        setTimesheets(prevTimesheets =>
          prevTimesheets.filter(ts => ts.id !== timesheetID)
        )

        alert("Timesheet deleted successfully.")
      } catch (error) {
        console.error("Error deleting timesheet:", error)
        alert("Error deleting timesheet.")
      }
    }
  }

  const handleFileUpload = async event => {
    const file = event.target.files[0]
    if (file) {
      setNewAttachments([...newAttachments, file]) // Add file to the new attachments array
    }
  }

  const handleSaveAttachments = async () => {
    try {
      const storage = getStorage()
      const attachmentURLs = []

      // Upload all new attachments
      for (let file of newAttachments) {
        const storageReference = storageRef(
          storage,
          `timesheets/${selectedTimesheet.jobID}/attachments/${file.name}`
        )
        await uploadBytes(storageReference, file)
        const downloadURL = await getDownloadURL(storageReference)
        attachmentURLs.push({
          name: file.name,
          url: downloadURL,
        })
      }

      // Update the timesheet with new attachment URLs immutably
      const updatedTimesheet = {
        ...selectedTimesheet,
        attachments: [
          ...(selectedTimesheet.attachments || []),
          ...attachmentURLs,
        ],
      }

      await TimesheetDataService.updateTimesheet(
        selectedTimesheet.id,
        updatedTimesheet
      )

      setTimesheets(prevTimesheets =>
        prevTimesheets.map(ts =>
          ts.id === selectedTimesheet.id ? updatedTimesheet : ts
        )
      )

      setSelectedTimesheet(updatedTimesheet)
      setNewAttachments([]) // Clear new attachments after upload

      alert("Attachments uploaded successfully!")
    } catch (error) {
      console.error("Error uploading files:", error)
      alert("Error uploading files.")
    }
  }

  const handleStatusFieldChange = e => {
    const newStatus = e.value
    setStatus(newStatus)

    // Show or hide the Reason field based on the selected status
    if (newStatus === "Dispute" || newStatus === "Rejected") {
      setIsReasonVisible(true)
    } else {
      setIsReasonVisible(false)
      setDisputeReason("") // Reset dispute reason when not needed
    }
  }

  const handleStatusChange = async newStatus => {
    if (!selectedTimesheet) return

    // Validation for Dispute or Rejected status to ensure reason is provided
    if (
      (newStatus === "Dispute" || newStatus === "Rejected") &&
      !disputeReason
    ) {
      alert("Please provide a reason for dispute or rejection.")
      return
    }

    try {
      const updatedTimesheet = {
        ...selectedTimesheet,
        status: newStatus,
        disputeReason: disputeReason || null, // Ensure disputeReason is saved
      }

      await TimesheetDataService.updateTimesheet(
        selectedTimesheet.id,
        updatedTimesheet
      )

      setTimesheets(prevTimesheets =>
        prevTimesheets.map(ts =>
          ts.id === selectedTimesheet.id ? updatedTimesheet : ts
        )
      )

      const message = `
  <div style="font-family: Arial, sans-serif; color: #333;">
    <h2 style="color: #4CAF50;">Timesheet Update</h2>
    <p>
      The timesheet for <strong>Job ID: ${selectedTimesheet.jobID}</strong> 
      has been <strong style="text-transform: capitalize; color: ${
        newStatus === "Approved" ? "#28a745" : "#dc3545"
      };">${newStatus.toLowerCase()}</strong>.
    </p>
    ${
      newStatus === "Rejected" || newStatus === "Dispute"
        ? `<p style="margin-top: 10px; color: #e63946;">
             <strong>Reason:</strong> ${disputeReason}
           </p>`
        : ""
    }
    <p style="margin-top: 20px;">Thank you for your attention.</p>
  </div>
`

      // Construct the email data
      // const formData = {
      //   email: selectedTimesheet.workerEmail, // "delakti@yahoo.co.uk", // Recipient's email
      //   subject: `Timesheet ${newStatus}`, // Email subject
      //   message: `The timesheet with Job ID: ${
      //     selectedTimesheet.jobID
      //   } has been ${newStatus.toLowerCase()}. ${
      //     newStatus === "Rejected" || newStatus === "Dispute"
      //       ? `Reason: ${disputeReason}`
      //       : ""
      //   }`, // Email body
      // }

      // // Send email using Axios
      // await axios.post(API_URL, formData)

      try 
      {
        console.log("Sending to: " + JSON.stringify(selectedTimesheet.workerEmail));

        await sendEmail(
          {
            to_email: selectedTimesheet.workerEmail,
            jobID: selectedTimesheet.jobID,
            status: newStatus,
            reason:
              newStatus === "Rejected" || newStatus === "Dispute"
                ? disputeReason
                : "N/A"
          },
          {
            templateID: "template_timesheet_statu",
            useAxios: true
          }
        );
  
      } catch (error) {
        console.log("Sending ERROR: " + JSON.stringify(error));
      }

      toast.success(`Status updated to ${newStatus} and email sent.`)

      handleClosePopup()
    } catch (error) {
      console.error("Error updating status:", error)
    }
  }

  return (
    <div className="page-content">
      <h1>Approve Timesheets</h1>
      <span
        style={{ float: "right" }}
      >{`Displaying ${filteredTimesheets.length} timesheet(s)`}</span>
      {/* Styled Search Input */}
      <div className="search-bar" style={{ marginTop: "20px" }}>
        <input
          type="text"
          placeholder="Search timesheets..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <DataGrid
          dataSource={filteredTimesheets}
          keyExpr="id"
          showBorders={true}
        >
          <Column dataField="jobID" caption="Job ID" />
          <Column dataField="workerID" caption="Worker ID" />
          <Column dataField="status" caption="Status" />
          <Column dataField="fromDuration" caption="From" />
          <Column dataField="toDuration" caption="To" />
          <Column
            dataField="breakTime"
            caption="Break Time (minutes)"
            dataType="number"
          />
          <Column
            type="buttons"
            buttons={[
              {
                hint: "View Details",
                icon: "info",
                onClick: e => handleShowPopup(e.row.data),
              },
              {
                hint: "Delete",
                icon: "trash",
                onClick: e => handleDeleteTimesheet(e.row.data.id), // Call delete function
              },
            ]}
          />
        </DataGrid>
      </div>
      {Object.keys(selectedTimesheet).length > 0 && ( // Only render popup if selectedTimesheet is not empty
        <Popup
          visible={showPopup}
          onHiding={handleClosePopup}
          dragEnabled={true}
          closeOnOutsideClick={true}
          showTitle={true}
          title="Timesheet Details"
          width={600}
          height={600}
        >
          <div>
            <fieldset>
              <legend>Attachment(s)</legend>
              {selectedTimesheet.attachments &&
              selectedTimesheet.attachments[0] ? (
                <div className="attachments-section">
                  {/* <h4 className="attachments-title">Attachments</h4> */}
                  <ul className="attachments-list">
                    {selectedTimesheet.attachments.map((attachment, index) => (
                      <li key={index} className="attachment-item">
                        <a
                          href={attachment.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="attachment-link"
                        >
                          {attachment.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className="no-attachments">No attachments available.</div>
              )}

              {/* Add new attachment */}
              <div className="add-attachment-section">
                <h4 className="add-attachment-title">Add Attachment</h4>
                <input
                  type="file"
                  style={{ width: "100%" }}
                  onChange={handleFileUpload}
                  className="attachment-input"
                />
              </div>

              {newAttachments.length > 0 && (
                <div className="new-attachments-section">
                  <h5 className="new-attachments-title">
                    New Attachments Ready to Upload
                  </h5>
                  <ul className="new-attachments-list">
                    {newAttachments.map((file, index) => (
                      <li key={index} className="new-attachment-item">
                        {file.name}
                      </li>
                    ))}
                  </ul>
                  <Button
                    text="Save Attachments"
                    onClick={handleSaveAttachments}
                    className="save-attachments-button"
                  />
                </div>
              )}
              <div></div>
            </fieldset>

            <fieldset style={{ marginTop: "10px" }}>
              <legend>Timesheet Information</legend>
              <Form formData={selectedTimesheet}>
                <SimpleItem dataField="jobID" label={{ text: "Job ID" }} />
                <SimpleItem
                  dataField="workerID"
                  label={{ text: "Worker" }}
                  render={() => <div>{workerFullName || "Loading..."}</div>} // Display the worker full name or a loading message
                />
                <SimpleItem
                  dataField="fromDuration"
                  label={{ text: "Duration From" }}
                />
                <SimpleItem
                  dataField="toDuration"
                  label={{ text: "Duration To" }}
                />
                <SimpleItem
                  dataField="breakTime"
                  label={{ text: "Break Time" }}
                />

                {/* Status field with required validation */}
                <SimpleItem
                  dataField="status"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: ["Approved", "Dispute", "Rejected"],
                    value: status,
                    onValueChanged: handleStatusFieldChange,
                  }}
                  label={{ text: "Status" }}
                >
                  <RequiredRule message="Status is required" />
                </SimpleItem>

                {/* Conditional Dispute/Reject Reason field */}
                {isReasonVisible && (
                  <SimpleItem
                    dataField="disputeReason"
                    editorType="dxTextArea"
                    editorOptions={{
                      value: disputeReason,
                      onValueChanged: e => setDisputeReason(e.value),
                    }}
                    label={{ text: "Reason for Dispute/Reject" }}
                  >
                    <RequiredRule message="Reason is required for Dispute/Rejected status" />
                  </SimpleItem>
                )}
              </Form>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Button
                  text="Approve"
                  type="success"
                  onClick={() => handleStatusChange("Approved")}
                  disabled={status !== "Approved"} // Enable only if "Approved" is selected
                />
                <Button
                  text="Reject"
                  type="danger"
                  onClick={() => handleStatusChange("Rejected")}
                  disabled={status !== "Rejected"} // Enable only if "Rejected" is selected
                />
                <Button
                  text="Raise Dispute"
                  onClick={() => handleStatusChange("Dispute")}
                  disabled={status !== "Dispute"} // Enable only if "Dispute" is selected
                />
                <Button text="Close" onClick={handleClosePopup} />
              </div>
            </fieldset>
          </div>
        </Popup>
      )}
    </div>
  )
};

export default ApproveTimesheets;
