import {
    getDatabase,
    ref,
    push,
    onValue,
    update,
    remove,
    query,
    orderByChild,
  } from "firebase/database"
  
  class ProviderDataService {
    constructor() {
      // Reference to the 'providers' path in Firebase Realtime Database
      this.dbRef = ref(getDatabase(), "/providers")
    }
  
    /**
     * Fetch all providers ordered by creation timestamp (descending)
     */
    getAll() {
      return new Promise((resolve, reject) => {
        const providersByLatestRef = query(this.dbRef, orderByChild("createdAt"))
  
        onValue(
          providersByLatestRef,
          snapshot => {
            const data = snapshot.val()
            let providers = []
  
            if (data) {
              providers = Object.keys(data).map(key => ({
                key,
                ...data[key],
              }))
              providers.sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0))
            }
  
            resolve(providers)
          },
          error => {
            reject(error)
          }
        )
      })
    }
  
    /**
     * Create a new provider entry
     * @param {Object} provider - Provider form data (e.g. name, email, position, message, createdAt)
     */
    createProvider(provider) {
      return push(this.dbRef, provider)
    }
  
    /**
     * Update an existing provider
     * @param {string} key - Firebase node key
     * @param {Object} value - Updated field values
     */
    updateProvider(key, value) {
      const providerRef = ref(getDatabase(), `/providers/${key}`)
      return update(providerRef, value)
    }
  
    /**
     * Delete a provider by key
     * @param {string} key - Firebase node key
     */
    deleteProvider(key) {
      const providerRef = ref(getDatabase(), `/providers/${key}`)
      return remove(providerRef)
    }
  }
  
  export default new ProviderDataService()
  