// Firebase imports
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push, onValue, update, remove, query, orderByChild } from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage"
import { v4 as uuidv4 } from "uuid"

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const storage = getStorage(app);

class FinanceDataService {
  constructor() {
    this.dbRef = ref(getDatabase(), "/finance")
  }

  /**
   * Uploads a file to Firebase Storage and saves its URL in the database.
   * @param {File} file - The file to be uploaded.
   * @returns {Promise<object>} - Returns a promise resolving to the created invoice object.
   */
  async createInvoiceWithFile(file, company) {
    if (!file) {
      throw new Error("File is required for invoice creation.")
    }

    // Generate a unique ID for the invoice
    const invoiceId = uuidv4()

    // Create a storage reference in Firebase
    const fileRef = storageRef(storage, `invoices/${invoiceId}/${file.name}`)

    // Upload the file to Firebase Storage
    await uploadBytes(fileRef, file)

    // Get the download URL for the uploaded file
    const downloadURL = await getDownloadURL(fileRef)

    // Create the invoice object
    const timestamp = Date.now() // Current timestamp

    const newInvoice = {
      id: invoiceId,
      fileName: file.name,
      fileURL: downloadURL,
      createdAt: timestamp, 
      company: company
    }

    // Add the invoice to the database
    await this.createFinance(newInvoice)

    return newInvoice // Return the created invoice object
  }

  /**
   * Retrieves a specific invoice by its key.
   * @param {string} key - The key of the invoice to retrieve.
   * @returns {Promise<object>} - Resolves to the invoice object.
   */
  async getInvoiceByKey(key) {
    const specificFinanceRef = ref(getDatabase(), `/finance/${key}`)
    return new Promise((resolve, reject) => {
      onValue(
        specificFinanceRef,
        snapshot => {
          const data = snapshot.val()
          if (data) {
            resolve({ key, ...data })
          } else {
            reject(new Error("Invoice not found"))
          }
        },
        error => reject(error)
      )
    })
  }

  /**
   * Deletes a file from Firebase Storage associated with an invoice.
   * @param {string} invoiceId - The ID of the invoice.
   * @param {string} fileName - The name of the file to delete.
   * @returns {Promise<void>} - Resolves when the file is deleted.
   */
  async deleteInvoiceFile(invoiceId, fileName) {
    if (!invoiceId || !fileName) {
      throw new Error("Invoice ID and file name are required for deletion.")
    }

    // Create a reference to the file in Firebase Storage
    const fileRef = storageRef(storage, `invoices/${invoiceId}/${fileName}`)

    // Delete the file from Firebase Storage
    try {
      await remove(fileRef)
      console.log("File deleted successfully.")
    } catch (error) {
      console.error("Failed to delete file:", error)
      throw error
    }
  }

  getFinanceCount(callback) {
    return onValue(this.dbRef, snapshot => {
      const data = snapshot.val()
      const count = data ? Object.keys(data).length : 0
      callback(count)
    })
  }

  async uploadFile(financeRefKey, file) {
    if (!file || !financeRefKey) {
      throw new Error(
        "File and Finance Reference Key are required for uploading."
      )
    }

    // Create a storage reference in Firebase
    const fileRef = storageRef(storage, `finance/${financeRefKey}/${file.name}`)

    // Upload the file to Firebase Storage
    await uploadBytes(fileRef, file)

    // Get the download URL
    const downloadURL = await getDownloadURL(fileRef)

    // Update the finance record with the file URL
    const specificFinanceRef = ref(getDatabase(), `/finance/${financeRefKey}`)
    return update(specificFinanceRef, { fileURL: downloadURL })
  }

  async olduploadFile(invoiceID, file) {
    if (!file || !invoiceID) {
      throw new Error("File and Invoice ID are required for uploading.")
    }

    // Create a storage reference in Firebase
    const fileRef = storageRef(storage, `finance/${invoiceID}/${file.name}`)

    // Upload the file to Firebase Storage
    await uploadBytes(fileRef, file)

    // Get the download URL
    const downloadURL = await getDownloadURL(fileRef)

    // Update the finance record with the file URL
    const specificFinanceRef = ref(getDatabase(), `/finance/${invoiceID}`)
    return update(specificFinanceRef, { fileURL: downloadURL })
  }

  // getAll(callback) {
  //   return onValue(this.dbRef, snapshot => {
  //     const data = snapshot.val()
  //     let finance = []
  //     if (data) {
  //       finance = Object.keys(data).map(key => ({ key, ...data[key] }))
  //     }
  //     callback(finance)
  //   })
  // }

  getAll(callback) {
    // Query to order finance records by the 'createdAt' field
    const financeByLatestRef = query(this.dbRef, orderByChild("createdAt"))

    return onValue(financeByLatestRef, snapshot => {
      const data = snapshot.val()
      let finance = []

      if (data) {
        // Convert the object into an array of finance records and include the 'key' property
        finance = Object.keys(data).map(key => ({ key, ...data[key] }))

        // Sort finance records by 'createdAt' in descending order (latest first)
        finance.sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0)) // Descending order
      }

      if (typeof callback === "function") {
        callback(finance) // Pass sorted finance records to the callback
      } else {
        console.error("Callback is not a function")
      }
    })
  }

  createFinance(finance) {
    return push(this.dbRef, finance).then(ref => ref.key)
  }

  oldcreateFinance(finance) {
    return push(this.dbRef, finance)
  }

  updateFinance(key, value) {
    const specificFinanceRef = ref(getDatabase(), `/finance/${key}`)
    return update(specificFinanceRef, value)
  }

  deleteFinance(key) {
    const specificFinanceRef = ref(getDatabase(), `/finance/${key}`)
    return remove(specificFinanceRef)
  }
}

export default new FinanceDataService();