import emailjs from '@emailjs/browser';
import axios from 'axios';

/**
 * Sends an email using EmailJS SDK or fallback via Axios API.
 *
 * @param {Object} templateParams - Email template parameters.
 * @param {Object} [options] - Optional configuration.
 * @param {string} [options.templateID] - Custom EmailJS template ID (overrides default).
 * @param {boolean} [options.useAxios=false] - Whether to use Axios instead of the EmailJS SDK.
 * @returns {Promise} - Resolves on success, rejects on failure.
 */
export const sendEmail = async (templateParams, options = {}) => {
    const {
        templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        useAxios = false
    } = options;

    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
    const privateKey = process.env.REACT_APP_EMAILJS_PRIVATE_KEY;

    console.log('Service ID:', serviceID);
    console.log('Template ID:', templateID);
    console.log('Public Key:', publicKey);
    console.log('Private Key:', privateKey);

    const emailjsURL = "https://api.emailjs.com/api/v1.0/email/send";

    const emailData = {
        service_id: serviceID,
        template_id: templateID,
        user_id: publicKey,
        template_params: templateParams,
        accessToken: privateKey
    };

    const headers = {
        "Content-Type": "application/json"
    };

    try {
        if (useAxios) {
            const response = await axios.post(emailjsURL, JSON.stringify(emailData), { headers });
            console.log('✅ Email sent successfully via Axios:', response.data);
            return response.data;
        } else {
            const response = await emailjs.send(serviceID, templateID, templateParams, publicKey);
            console.log('✅ Email sent successfully via EmailJS SDK:', response);
            return response;
        }
    } catch (error) {
        console.error('❌ Email sending failed:', error);

        if (error instanceof emailjs.EmailJSResponseStatus) {
            console.error('🔍 Error Details (EmailJSResponseStatus):', {
                status: error.status,
                text: error.text
            });
        } else if (error.response) {
            console.error('❗ Error Data:', error.response.data);
            console.error('❗ Error Status:', error.response.status);
        } else if (error.request) {
            console.error('❗ Error Request:', error.request);
        } else {
            console.error('❗ Error Message:', error.message);
        }

        throw new Error('Email sending failed. Please try again later.');
    }
};
