import React, { useState, useEffect } from "react"
import "./invoiceadmin.css" // Custom CSS for Apple-style design
import FinanceDataService from "./../../services/FinanceDataService.js"
import { Button, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"

const InvoiceAdmin = () => {
  const [invoices, setInvoices] = useState([])

  // Fetch all invoices on component mount
  useEffect(() => {
    FinanceDataService.getAll(fetchedInvoices => {
      setInvoices(fetchedInvoices)
    })
  }, [])

  return (
    <div className="page-content-admin">
      <header className="admin-header">
        <h1>All Company Uploads</h1>
        <p className="admin-subtitle">Manage and review uploaded invoices</p>
      </header>

      <div className="admin-invoice-grid">
        {invoices.length > 0 ? (
          invoices.map(invoice => (
            <Card key={invoice.id} className="admin-invoice-card">
              <CardBody>
                <CardTitle tag="h4" className="invoice-title">
                  {invoice.fileName}
                </CardTitle>
                <CardSubtitle className="invoice-subtitle">
                  Uploaded by: <strong>{invoice.company}</strong>
                </CardSubtitle>
                <p className="invoice-date">
                  Uploaded on: {new Date(invoice.createdAt).toLocaleString()}
                </p>
                <Button
                  color="primary"
                  size="sm"
                  href={invoice.fileURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Invoice
                </Button>
              </CardBody>
            </Card>
          ))
        ) : (
          <p className="no-invoices-message">No invoices uploaded yet.</p>
        )}
      </div>
    </div>
  )
}

export default InvoiceAdmin
