import React, { useState, useEffect } from "react"
import "./invoiceagent.css" // Apple-inspired styling
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import FinanceDataService from "./../../services/FinanceDataService.js"

const InvoiceAgent = () => {
  const [invoices, setInvoices] = useState([])
  const [filteredInvoices, setFilteredInvoices] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [file, setFile] = useState(null)
  const [company, setCompany] = useState("")

  // Fetch user company from localStorage
  useEffect(() => {
    const authUserDetails = localStorage.getItem("authUserDetails")
    if (authUserDetails) {
      const userDetails = JSON.parse(authUserDetails)
      const userCompany = userDetails.userCompany
      setCompany(userCompany)
    } else {
      console.log("No user details found in localStorage.")
    }
  }, [])

  // Fetch invoices on component mount
  useEffect(() => {
    FinanceDataService.getAll(fetchedInvoices => {
      setInvoices(fetchedInvoices)
    })
  }, [])

  // Filter invoices based on the logged-in user's company
  useEffect(() => {
    if (company && invoices.length > 0) {
      const filtered = invoices.filter(invoice => invoice.company === company)
      setFilteredInvoices(filtered)
    }
  }, [company, invoices])

  // Handle file input change
  const handleFileChange = event => {
    setFile(event.target.files[0])
  }

  // Handle file upload
  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file before uploading.")
      return
    }

    try {
      const newInvoice = await FinanceDataService.createInvoiceWithFile(
        file,
        company
      )
      setInvoices(prevInvoices => [...prevInvoices, newInvoice])
      setFile(null)
      setShowModal(false)
    } catch (error) {
      console.error("Failed to upload file:", error)
      alert("An error occurred while uploading the file.")
    }
  }

  return (
    <div className="page-content">
      <header className="agent-header">
        <h1>Finance</h1>
        <p className="agent-subtitle">
          View and upload invoices for your company
        </p>
      </header>

      <Button className="upload-btn" onClick={() => setShowModal(true)}>
        Upload Invoice
      </Button>

      <div className="invoice-grid">
        {filteredInvoices.length > 0 ? (
          filteredInvoices.map(invoice => (
            <div key={invoice.id} className="invoice-card">
              <h4 className="invoice-title">{invoice.fileName}</h4>
              <p className="invoice-date">
                Uploaded on: {new Date(invoice.createdAt).toLocaleString()}
              </p>
              <a
                href={invoice.fileURL}
                target="_blank"
                rel="noopener noreferrer"
                className="view-invoice-link"
              >
                View Invoice
              </a>
            </div>
          ))
        ) : (
          <p className="no-invoices-message">No invoices uploaded yet.</p>
        )}
      </div>

      {/* Modal for uploading invoices */}
      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          Upload Invoice
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="fileInput">Select Invoice File</Label>
              <Input type="file" id="fileInput" onChange={handleFileChange} />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpload}>
            Upload
          </Button>
          <Button color="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default InvoiceAgent
