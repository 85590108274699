import {
    getDatabase,
    ref,
    push,
    onValue,
    update,
    remove,
    query,
    orderByChild,
  } from "firebase/database"
  
  class ClientDataService {
    constructor() {
      this.dbRef = ref(getDatabase(), "/clients")
    }
  
    getAll() {
      return new Promise((resolve, reject) => {
        const orderedRef = query(this.dbRef, orderByChild("createdAt"))
  
        onValue(
          orderedRef,
          snapshot => {
            const data = snapshot.val()
            let clients = []
  
            if (data) {
              clients = Object.keys(data).map(key => ({
                key,
                ...data[key],
              }))
              clients.sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0))
            }
  
            resolve(clients)
          },
          error => {
            reject(error)
          }
        )
      })
    }
  
    createClient(client) {
      return push(this.dbRef, client)
    }
  
    updateClient(key, value) {
      const clientRef = ref(getDatabase(), `/clients/${key}`)
      return update(clientRef, value)
    }
  
    deleteClient(key) {
      const clientRef = ref(getDatabase(), `/clients/${key}`)
      return remove(clientRef)
    }
  }
  
  export default new ClientDataService()
  