import React, { useState } from "react"
import { sendEmail } from "../../services/emailService"
import Header from "../../components/External/Header.js"
import Footer from "../../components/External/Footer.js"
import ProviderDataService from '../../services/ProviderDataService'
import "./ProviderForm.css"

const ProviderForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    position: "",
    message: "",
  })

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const userTemplateParams = {
      to_email: formData.email,
      user_name: formData.name,
      job_position: formData.position,
    }

    const adminTemplateParams = {
      to_email: "mailer@care-tip.com",
      user_name: formData.name,
      job_position: formData.position,
      message: formData.message,
    }

    try {
      // Send acknowledgment email to user
      await sendEmail(userTemplateParams, {
        templateID: "template_provider_userac",
        useAxios: true,
      })

      // Send alert email to admin
      await sendEmail(adminTemplateParams, {
        templateID: "template_provider_alert",
        useAxios: true,
      })

      // Store in Firestore
      await ProviderDataService.createProvider({
        name: formData.name,
        email: formData.email,
        position: formData.position,
        message: formData.message,
        createdAt: Date.now(),
      })

      alert(
        "✅ Application submitted successfully! Check your email for confirmation."
      )

      // Optionally reset the form
      setFormData({
        name: "",
        email: "",
        position: "",
        message: "",
      })
    } catch (error) {
      console.error("❌ Email or Firebase error:", error)
      alert("❌ Submission failed. Please try again.")
    }
  }

  return (
    <>
      <Header />
      <div className="page-contentc">
    
        <form onSubmit={handleSubmit} style={{ maxWidth: "900px", margin: "0 auto", display: 'block' }}>
    <h2 style={{marginTop: '20px'}}>Register</h2> 
     <div style={{ marginBottom: "15px" }}>
    <label>Name:</label>
    <input
      type="text"
      name="name"
      value={formData.name}
      onChange={handleChange}
      required
      style={{ width: "100%", padding: "8px" }}
    />
  </div>

  <div style={{ marginBottom: "15px" }}>
    <label>Email:</label>
    <input
      type="email"
      name="email"
      value={formData.email}
      onChange={handleChange}
      required
      style={{ width: "100%", padding: "8px" }}
    />
  </div>

  <div style={{ marginBottom: "15px" }}>
    <label>Position:</label>
    <input
      type="text"
      name="position"
      value={formData.position}
      onChange={handleChange}
      required
      style={{ width: "100%", padding: "8px" }}
    />
  </div>

  <div style={{ marginBottom: "15px" }}>
    <label>Message:</label>
    <textarea
      name="message"
      value={formData.message}
      onChange={handleChange}
      required
      style={{ width: "100%", height: "120px", padding: "8px" }}
    />
  </div>

  <div style={{ paddingTop: "10px", marginBottom:'20px' }}>
    <button type="submit" style={{ padding: "10px 20px" }}>
      Submit
    </button>
  </div>
</form>

      </div>
      <Footer />
    </>
  )
}

export default ProviderForm
