import React, { useState, useEffect } from "react"
import TimesheetDataService from "services/TimesheetDataService"
import WorkersDataService from "services/WorkersDataService"
import { Popup } from "devextreme-react/popup"
import { Button } from "devextreme-react/button"
import { Form, SimpleItem, RequiredRule } from "devextreme-react/form"
import DataGrid, { Column } from "devextreme-react/data-grid"
import "devextreme/dist/css/dx.light.css"
import "./approve.css"

const ApproveClientTimesheets = () => {
  const [timesheets, setTimesheets] = useState([])
  const [filteredTimesheets, setFilteredTimesheets] = useState([]) // For filtered timesheets
  const [selectedTimesheet, setSelectedTimesheet] = useState({}) // Initialize as empty object
  const [showPopup, setShowPopup] = useState(false)
  const [disputeReason, setDisputeReason] = useState("")
  const [status, setStatus] = useState("") // Track the selected status
  const [isReasonVisible, setIsReasonVisible] = useState(false)
  const [workerFullName, setWorkerFullName] = useState("") // State to store worker full name
  const [newAttachments, setNewAttachments] = useState([]) // Store new attachments before upload
  const [company, setCompany] = useState("") // Store user's company (provider)
  const [searchTerm, setSearchTerm] = useState("") // Global search term

  // Fetch user company from localStorage and set to state
  useEffect(() => {
    const authUserDetails = localStorage.getItem("authUserDetails")
    if (authUserDetails) {
      const userDetails = JSON.parse(authUserDetails)
      const userCompany = userDetails.userCompany
      setCompany(userCompany)
    } else {
      console.log("No user details found in localStorage.")
    }
  }, [])

  // Fetch all timesheets on component mount
  useEffect(() => {
    const fetchTimesheets = async () => {
      try {
        const allTimesheets = await TimesheetDataService.getAllTimesheets()
        setTimesheets(allTimesheets)
      } catch (error) {
        console.error("Error fetching timesheets:", error)
      }
    }

    fetchTimesheets()
  }, [])

  // Filter timesheets based on the logged-in user's company (provider)
  useEffect(() => {
    console.log("Filter timesheets based on" + JSON.stringify(company))
        console.log("Timesheets: " + JSON.stringify(timesheets))

    if (company && timesheets.length > 0) {
      const filtered = timesheets.filter(ts => ts.provider === company)
      setFilteredTimesheets(filtered)
    }
  }, [company, timesheets])

  const handleSearchChange = e => {
    const term = e.target.value.toLowerCase()
    setSearchTerm(term)

    if (term === "") {
      setFilteredTimesheets(timesheets.filter(ts => ts.provider === company)) // Reset if search is empty
    } else {
      const filtered = timesheets.filter(
        ts =>
          ts.jobID.toLowerCase().includes(term) ||
          ts.workerID.toLowerCase().includes(term) ||
          ts.status.toLowerCase().includes(term) ||
          (ts.fromDuration && ts.fromDuration.toLowerCase().includes(term)) ||
          (ts.toDuration && ts.toDuration.toLowerCase().includes(term)) ||
          ts.provider === company
      )
      setFilteredTimesheets(filtered)
    }
  }

  const handleShowPopup = async timesheet => {
    const timesheetWithAttachments = {
      ...timesheet,
      attachments: timesheet.attachments || [],
    }

    setSelectedTimesheet(timesheetWithAttachments)
    setShowPopup(true)
    setStatus(timesheetWithAttachments.status || "")
    setDisputeReason(timesheetWithAttachments.disputeReason || "")
    setIsReasonVisible(
      timesheetWithAttachments.status === "Dispute" ||
        timesheetWithAttachments.status === "Rejected"
    )

    if (timesheetWithAttachments.workerID) {
      const workerName = await fetchWorkerName(
        timesheetWithAttachments.workerID
      )
      setWorkerFullName(workerName)
    }
  }

  const handleClosePopup = () => {
    setShowPopup(false)
    setSelectedTimesheet({})
    setDisputeReason("")
    setStatus("")
    setIsReasonVisible(false)
    setWorkerFullName("")
    setNewAttachments([])
  }

  const fetchWorkerName = async workerID => {
    try {
      const workerFullName = await WorkersDataService.getWorkerFullNameById(
        workerID
      )
      return workerFullName
    } catch (error) {
      console.error("Error fetching worker full name:", error)
      return ""
    }
  }

  const handleDeleteTimesheet = async timesheetID => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this timesheet?"
    )

    if (confirmDelete) {
      try {
        await TimesheetDataService.deleteTimesheet(timesheetID)
        setTimesheets(prevTimesheets =>
          prevTimesheets.filter(ts => ts.id !== timesheetID)
        )
        alert("Timesheet deleted successfully.")
      } catch (error) {
        console.error("Error deleting timesheet:", error)
        alert("Error deleting timesheet.")
      }
    }
  }

  const handleStatusChange = async newStatus => {
    if (!selectedTimesheet) return

    if (  (newStatus === "Dispute" || newStatus === "Rejected") && !disputeReason ) 
    {
      alert("Please provide a reason for dispute or rejection.")
      return
    }

    try {
      const updatedTimesheet = {
        ...selectedTimesheet,
        status: newStatus,
        disputeReason: disputeReason || null,
      }
      await TimesheetDataService.updateTimesheet(
        selectedTimesheet.id,
        updatedTimesheet
      )

      setTimesheets(prevTimesheets =>
        prevTimesheets.map(ts =>
          ts.id === selectedTimesheet.id ? updatedTimesheet : ts
        )
      )

      handleClosePopup()
    } catch (error) {
      console.error("Error updating status:", error)
    }
  }

  return (
    <div className="page-content">
      <h1>Approve Timesheets</h1>
      <span
        style={{ float: "right" }}
      >{`Displaying ${filteredTimesheets.length} timesheet(s)`}</span>
      {/* Search Bar */}
      <div className="search-bar" style={{ marginTop: "20px" }}>
        <input
          type="text"
          placeholder="Search timesheets..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <DataGrid
          dataSource={filteredTimesheets}
          keyExpr="id"
          showBorders={true}
        >
          <Column dataField="jobID" caption="Job ID" />
          <Column dataField="workerID" caption="Worker ID" />
          <Column dataField="status" caption="Status" />
          <Column dataField="fromDuration" caption="From" />
          <Column dataField="toDuration" caption="To" />
          <Column
            dataField="breakTime"
            caption="Break Time (minutes)"
            dataType="number"
          />
          <Column
            type="buttons"
            buttons={[
              {
                hint: "View Details",
                icon: "info",
                onClick: e => handleShowPopup(e.row.data),
              },
              {
                hint: "Delete",
                icon: "trash",
                onClick: e => handleDeleteTimesheet(e.row.data.id),
              },
            ]}
          />
        </DataGrid>
      </div>

      {Object.keys(selectedTimesheet).length > 0 && (
        <Popup
          visible={showPopup}
          onHiding={handleClosePopup}
          dragEnabled={true}
          closeOnOutsideClick={true}
          showTitle={true}
          title="Timesheet Details"
          width={600}
          height={600}
        >
          <div>
            <fieldset>
              <legend>Attachment(s)</legend>
            {selectedTimesheet.attachments &&
              selectedTimesheet.attachments[0] ? (
                <div className="attachments-section">
                  <ul className="attachments-list">
                    {selectedTimesheet.attachments.map((attachment, index) => (
                      <li key={index} className="attachment-item">
                        <a
                          href={attachment.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="attachment-link"
                        >
                          {attachment.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className="no-attachments">No attachments available.</div>
              )}
            </fieldset>

            <fieldset style={{ marginTop: "10px" }}>
              <legend>Timesheet Information</legend>
              <Form formData={selectedTimesheet}>
                <SimpleItem dataField="jobID" label={{ text: "Job ID" }} />
                <SimpleItem
                  dataField="workerID"
                  label={{ text: "Worker" }}
                  render={() => <div>{workerFullName || "Loading..."}</div>}
                />
                <SimpleItem
                  dataField="fromDuration"
                  label={{ text: "Duration From" }}
                />
                <SimpleItem
                  dataField="toDuration"
                  label={{ text: "Duration To" }}
                />
                <SimpleItem
                  dataField="breakTime"
                  label={{ text: "Break Time" }}
                />

                <SimpleItem
                  dataField="status"
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: ["Approved", "Dispute", "Rejected"],
                    value: status,
                    onValueChanged: e => {
                      const selectedStatus = e.value
                      setStatus(selectedStatus)
                      setIsReasonVisible(selectedStatus === "Dispute" || selectedStatus === "Rejected")
                    },
                  }}
                  
                  label={{ text: "Status" }}
                >
                  <RequiredRule message="Status is required" />
                </SimpleItem>

                {isReasonVisible && (
                  <SimpleItem
                    dataField="disputeReason"
                    editorType="dxTextArea"
                    editorOptions={{
                      value: disputeReason,
                      onValueChanged: e => setDisputeReason(e.value),
                    }}
                    label={{ text: "Reason for Dispute/Reject" }}
                  >
                    <RequiredRule message="Reason is required" />
                  </SimpleItem>
                )}
              </Form>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Button
                  text="Approve"
                  type="success"
                  onClick={() => handleStatusChange("Approved")}
                  disabled={status !== "Approved"}
                />
                <Button
                  text="Reject"
                  type="danger"
                  onClick={() => handleStatusChange("Rejected")}
                  disabled={status !== "Rejected"}
                />
                <Button
                  text="Raise Dispute"
                  onClick={() => handleStatusChange("Dispute")}
                  disabled={status !== "Dispute"}
                />
                <Button text="Close" onClick={handleClosePopup} />
              </div>
            </fieldset>
          </div>
        </Popup>
      )}
    </div>
  )
}

export default ApproveClientTimesheets
