import React, { useEffect, useState } from "react"
import ClientDataService from "../../services/ClientDataService"
import Header from "../../components/External/Header"
import Footer from "../../components/External/Footer"

const ClientManagement = () => {
  const [clients, setClients] = useState([])
  const [editKey, setEditKey] = useState(null)
  const [editData, setEditData] = useState({})

  useEffect(() => {
    fetchClients()
  }, [])

  const fetchClients = async () => {
    try {
      const data = await ClientDataService.getAll()
      setClients(data)
    } catch (error) {
      console.error("Error fetching client data:", error)
    }
  }

  const handleEdit = client => {
    setEditKey(client.key)
    setEditData({ ...client })
  }

  const handleCancel = () => {
    setEditKey(null)
    setEditData({})
  }

  const handleChange = e => {
    setEditData({ ...editData, [e.target.name]: e.target.value })
  }

  const handleUpdate = async () => {
    try {
      await ClientDataService.updateClient(editKey, editData)
      fetchClients()
      handleCancel()
    } catch (error) {
      console.error("Error updating client:", error)
    }
  }

  const handleDelete = async key => {
    if (window.confirm("Are you sure you want to delete this client?")) {
      try {
        await ClientDataService.deleteClient(key)
        fetchClients()
      } catch (error) {
        console.error("Error deleting client:", error)
      }
    }
  }

  return (
    <>
      <Header />
      <div className="page-content">
        <h2>Client Management</h2>

        {clients.length === 0 ? (
          <p>No client records found.</p>
        ) : (
          <table border="1" cellPadding="8" style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead style={{ background: "#f1f1f1" }}>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>City</th>
                <th>Business</th>
                <th>No. of Homes</th>
                <th>Staff Required</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {clients.map(client => (
                <tr key={client.key}>
                  {editKey === client.key ? (
                    <>
                      <td>
                        <input
                          name="firstName"
                          value={editData.firstName || ""}
                          onChange={handleChange}
                          placeholder="First"
                        />
                        <input
                          name="lastName"
                          value={editData.lastName || ""}
                          onChange={handleChange}
                          placeholder="Last"
                        />
                      </td>
                      <td>
                        <input
                          name="phone"
                          value={editData.phone || ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          name="email"
                          value={editData.email || ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          name="city"
                          value={editData.city || ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          name="businessName"
                          value={editData.businessName || ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          name="noOfHomes"
                          value={editData.noOfHomes || ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <input
                          name="staffRequired"
                          value={editData.staffRequired || ""}
                          onChange={handleChange}
                        />
                      </td>
                      <td>
                        <button onClick={handleUpdate}>Save</button>
                        <button onClick={handleCancel} style={{ marginLeft: "6px" }}>Cancel</button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>{client.firstName} {client.lastName}</td>
                      <td>{client.phone}</td>
                      <td>{client.email}</td>
                      <td>{client.city}</td>
                      <td>{client.businessName}</td>
                      <td>{client.noOfHomes}</td>
                      <td>{client.staffRequired}</td>
                      <td>
                        <button onClick={() => handleEdit(client)}>Edit</button>
                        <button
                          onClick={() => handleDelete(client.key)}
                          style={{ color: "red", marginLeft: "6px" }}
                        >
                          Delete
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <Footer />
    </>
  )
}

export default ClientManagement
